@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/Lato/Light/Lato-Light.ttf") format('truetype'), url("/fonts/Lato/Light/Lato-Light.woff2") format('woff2'), url("/fonts/Lato/Light/Lato-Light.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Lato/Regular/Lato-Regular.ttf") format('truetype'), url("/fonts/Lato/Regular/Lato-Regular.woff2") format('woff2'), url("/fonts/Lato/Regular/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Lato/Bold/Lato-Bold.ttf") format('truetype'), url("/fonts/Lato/Bold/Lato-Bold.woff2") format('woff2'), url("/fonts/Lato/Bold/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/Lato/Black/Lato-Black.ttf") format('truetype'), url("/fonts/Lato/Black/Lato-Black.woff2") format('woff2'), url("/fonts/Lato/Black/Lato-Black.woff") format('woff');
}
.nav-tabs {
  position: fixed;
  bottom: -250px;
  left: 0;
  z-index: 3000;
  min-height: 300px;
  width: 100%;
  transition: all 0.2s ease;
}
.nav-tabs-opened {
  bottom: 0 !important;
  transition: all 0.2s ease;
}
.nav-tabs-full {
  top: 35px !important;
  height: 100%;
}
.tabs {
  z-index: 1000;
  width: 100%;
  height: 50px;
  background-color: #fff;
}
.tabs .tab {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-image: linear-gradient(to right, #ddd9d3, #f7f3ef 50%);
  color: #464749;
  font-weight: 700;
  font-size: 1.3rem;
}
.tabs .tab-black {
  background-color: #000;
  background-image: none;
  color: #fff;
}
.tabs .tab-selected {
  background-color: #fff;
  background-image: none;
  color: #000;
}
.tab-content {
  z-index: 1000;
  overflow: auto;
  overflow-x: hidden;
  padding-top: 20px;
  width: 100%;
  height: 250px;
  background-color: #fff;
}
.tab-content .oapp-item {
  visibility: visible;
  max-height: 2000px;
  opacity: 1;
  transition: max-height 0.5s cubic-bezier(1, 0, 1, 0) 0s, visibility 0s linear 0s, opacity 0.2s linear 0.5s;
}
.tab-content .oapp-item-hidden {
  visibility: hidden;
  max-height: 0px;
  opacity: 0;
  transition: max-height 0.6s cubic-bezier(0, 1, 0, 1) 0s, visibility 0s linear 0.5s, opacity 0.2s linear 0s;
}
.tab-content-full {
  height: 100%;
}
.clickaway-helper,
.clickaway-helper--header {
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 700;
  width: 100%;
  height: 100%;
  background-color: transparent;
}
.clickaway-helper--header {
  z-index: 2000;
  height: 35px;
}
/*# sourceMappingURL=src/app/components/layout/mobile-navigation-tabs.css.map */