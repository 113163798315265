@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/Lato/Light/Lato-Light.ttf") format('truetype'), url("/fonts/Lato/Light/Lato-Light.woff2") format('woff2'), url("/fonts/Lato/Light/Lato-Light.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Lato/Regular/Lato-Regular.ttf") format('truetype'), url("/fonts/Lato/Regular/Lato-Regular.woff2") format('woff2'), url("/fonts/Lato/Regular/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Lato/Bold/Lato-Bold.ttf") format('truetype'), url("/fonts/Lato/Bold/Lato-Bold.woff2") format('woff2'), url("/fonts/Lato/Bold/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/Lato/Black/Lato-Black.ttf") format('truetype'), url("/fonts/Lato/Black/Lato-Black.woff2") format('woff2'), url("/fonts/Lato/Black/Lato-Black.woff") format('woff');
}
.oapp-item {
  display: flex;
  flex-direction: column;
  margin-bottom: 2px;
  background-color: #fff;
}
.oapp-item:first-child {
  margin-top: 2px;
}
.oapp-item .oapp-item__header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #f4f4f4;
  cursor: pointer;
}
.oapp-item .oapp-item__header .oapp-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  border-right: 1px solid #f4f4f4;
  cursor: pointer;
}
.oapp-item .oapp-item__header .oapp-icon .icon {
  width: 45px;
  height: 45px;
}
.oapp-item .oapp-item__header .oapp-icon .spinner {
  width: 45px;
  height: 45px;
}
.oapp-item .oapp-item__header .oapp-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 61px;
  user-select: none;
}
.oapp-item .oapp-item__header .oapp-main .oapp-info {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 16px 8px;
  height: 100%;
}
.oapp-item .oapp-item__header .oapp-main .oapp-name {
  display: flex;
  align-items: center;
  padding-left: 16px;
  height: 100%;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1.3rem;
  transition: all 0.3s ease;
}
.oapp-item .oapp-item__header .oapp-main .oapp-name--collapsed {
  height: fit-content;
  color: #808080;
  font-weight: 400;
  font-size: 1.1rem;
  line-height: 1;
}
.oapp-item .oapp-item__header .oapp-main .oapp-value {
  margin-left: 16px;
  font-weight: 300;
  font-size: 1.6rem;
}
.oapp-item .oapp-item__header .oapp-main .oapp-close {
  margin-right: 10px;
  cursor: pointer;
}
.oapp-item .oapp-item__main {
  height: 0;
}
.oapp-item .oapp-item__footer {
  display: flex;
  justify-content: center;
  padding: 16px;
}
.oapp-item .oapp__slot {
  min-height: 400px;
  background-color: #f7f7f5;
}
.collapse-transition {
  overflow: hidden;
  transition: max-height 0.3s ease 0.2s;
}
.fade-enter-active,
.fade-leave-active {
  max-height: 2000px;
}
.fade-enter,
.fade-leave-to {
  max-height: 0px;
  transition: max-height 0.3s cubic-bezier(0, 1, 0, 1) 0s;
}
@media screen and (max-width: 500px) {
  .fade-enter,
  .fade-leave-to {
    transition: none;
  }
}
/*# sourceMappingURL=src/app/pages/data/components/oapp-item.css.map */