





















































































@import '~variables'

.building-checkbar
  position relative
  display flex
  background-color white
  .building-checkbar__status, .building-name
    padding 4px 0
  .building-checkbar__status
    display flex
    justify-content center
    align-items center
    width 25px
  .building-name
    flex 3
    font-weight 400
    font-size $fs-body
  .building-checkbar__selection
    display flex
    justify-content center
    align-items center
    padding $space-little
    width 20%
    border-top-right-radius $border-radius
    border-bottom-right-radius $border-radius
    background-color black
    color white
    font-weight 400
    transition all 0.3s ease
    @media screen and (max-width: 500px)
      height 100%

.drawer
  position absolute
  top -38px
  left 105%
  transition all 0.3s ease
  @media screen and (max-width: 500px)
    right 25%
    left 0
    z-index 100

.check-circle
  width 10px
  height @width
  border-radius 50%
  background-color $silver
  transition all 0.3s ease
  &--selected
    background-color black

.o-button
  padding 0
  width 100%
  box-shadow $shadow-elevation-1
  @media screen and (max-width: 500px)
    align-items center
    height 30px

// .building-checkbar__selection and .drawer transition
.fade-enter-active, .fade-leave-active
  opacity 1

.fade-enter, .fade-leave-to
  opacity 0
