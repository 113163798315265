


































































































































































@import '~variables'

.sidebar-node
  display flex
  flex-direction column
  height 100%
  .node-infos
    display flex
    flex 1
    flex-direction column
    margin-top $space-2
    padding $space-2
    .info-row
      display flex
      align-items flex-end
      margin-bottom $space-2
    .info-value
      &--name
        margin 0
        font-weight 700
        font-size $fs-big
        line-height 1
      &--node-type
        margin-right $space-1
        margin-left $space-2
        text-transform uppercase
        font-size $fs-h3
      &--uuid
        color darken($grey, 20)
        font-size $fs-h3
  .node-actions
    display flex
    justify-content flex-start
    padding $space-2
    background-color white
    .o-button
      display flex
      justify-content center
      align-items center
      margin-right $space-2
      width fit-content
