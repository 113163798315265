


















































































































































































@import '~variables'

.oapp-item
  display flex
  flex-direction column
  margin-bottom $space-tiny
  background-color white
  &:first-child
    margin-top $space-tiny
  .oapp-item__header
    display flex
    justify-content flex-start
    align-items center
    width 100%
    border-bottom $grid-border
    cursor pointer
    .oapp-icon
      display flex
      justify-content center
      align-items center
      padding 8px
      border-right $grid-border
      cursor pointer
      .icon
        width 45px
        height @width
      .spinner
        width 45px
        height @width
    .oapp-main
      display flex
      justify-content space-between
      align-items center
      width 100%
      height 61px
      user-select none
      .oapp-info
        display flex
        flex 1
        flex-direction column
        padding $space-2 $space-1
        height 100%
      .oapp-name
        display flex
        align-items center
        padding-left $space-2
        height 100%
        text-transform uppercase
        font-weight 700
        font-size $fs-h2
        transition all 0.3s ease
        &--collapsed
          height fit-content
          color gray
          font-weight 400
          font-size $fs-h3
          line-height 1
      .oapp-value
        margin-left $space-2
        font-weight 300
        font-size $fs-h1
      .oapp-close
        margin-right 10px
        cursor pointer
  .oapp-item__main
    height 0
  .oapp-item__footer
    display flex
    justify-content center
    padding 16px
  .oapp__slot
    min-height 400px
    background-color #f7f7f5

// VUE.js transition classes
.collapse-transition
  overflow hidden
  transition max-height 0.3s ease 0.2s

.fade-enter-active, .fade-leave-active
  max-height 2000px

.fade-enter, .fade-leave-to
  max-height 0px
  transition max-height 0.3s cubic-bezier(0, 1, 0, 1) 0s
  @media screen and (max-width: 500px)
    transition none
