@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/Lato/Light/Lato-Light.ttf") format('truetype'), url("/fonts/Lato/Light/Lato-Light.woff2") format('woff2'), url("/fonts/Lato/Light/Lato-Light.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Lato/Regular/Lato-Regular.ttf") format('truetype'), url("/fonts/Lato/Regular/Lato-Regular.woff2") format('woff2'), url("/fonts/Lato/Regular/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Lato/Bold/Lato-Bold.ttf") format('truetype'), url("/fonts/Lato/Bold/Lato-Bold.woff2") format('woff2'), url("/fonts/Lato/Bold/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/Lato/Black/Lato-Black.ttf") format('truetype'), url("/fonts/Lato/Black/Lato-Black.woff2") format('woff2'), url("/fonts/Lato/Black/Lato-Black.woff") format('woff');
}
.sidebar-data {
  overflow-y: auto;
  height: 100%;
  background-color: #fff;
}
.sidebar-data .fetching-loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.sidebar-data .fetching-loader .fetching-text {
  letter-spacing: 2px;
  font-weight: 700;
  font-size: 1.6rem;
}
.sidebar-data .header {
  display: flex;
  align-items: center;
  padding: 16px;
  background-color: #f4f4f4;
}
.sidebar-data .header .select-label {
  font-size: 1.3rem;
}
.sidebar-data .header .select-field {
  margin-left: 8px;
  width: 250px;
}
.sidebar-data .header .period-selector {
  margin-left: auto;
  width: fit-content;
  margin: 0 100px;
}
.sidebar-data .no-data {
  padding: 32px;
  text-align: center;
  letter-spacing: 2px;
  font-weight: 700;
  font-size: 1.6rem;
}
.sidebar-data .graph {
  padding: 16px;
}
.sidebar-data .graph .graph-info {
  display: flex;
  justify-content: center;
  padding: 8px;
  font-weight: 300;
  font-size: 1.1rem;
}
.sidebar-data .data-table {
  margin-bottom: 46px;
  padding: 16px;
  padding-top: 0;
}
/*# sourceMappingURL=src/app/pages/data/components/sidebar-data.css.map */