@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/Lato/Light/Lato-Light.ttf") format('truetype'), url("/fonts/Lato/Light/Lato-Light.woff2") format('woff2'), url("/fonts/Lato/Light/Lato-Light.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Lato/Regular/Lato-Regular.ttf") format('truetype'), url("/fonts/Lato/Regular/Lato-Regular.woff2") format('woff2'), url("/fonts/Lato/Regular/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Lato/Bold/Lato-Bold.ttf") format('truetype'), url("/fonts/Lato/Bold/Lato-Bold.woff2") format('woff2'), url("/fonts/Lato/Bold/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/Lato/Black/Lato-Black.ttf") format('truetype'), url("/fonts/Lato/Black/Lato-Black.woff2") format('woff2'), url("/fonts/Lato/Black/Lato-Black.woff") format('woff');
}
.sidebar-out .add-button {
  margin: 20px;
  padding: 10px 15px;
  border-width: thin;
  border-style: solid;
  border-color: #000;
  border-radius: 20px;
  background-color: #fff;
  color: #464749;
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1.6rem;
  cursor: pointer;
  transition: opacity 1.5s ease-in;
}
.sidebar-out .add-oapp-button {
  margin: 16px auto;
  width: fit-content;
  background-color: #fff;
  color: #000;
  text-transform: uppercase;
}
/*# sourceMappingURL=src/app/pages/data/components/sidebar-oapps.css.map */