


















































































































































































































































































@import '~variables'

.widget-modal >>> .modal-content
  display flex
  flex-direction column
  padding-bottom $space-3
  width 750px
  height 900px
  .modal__header
    display flex
    align-items center
    margin $space-3 0
    padding 0 $space-3
    .modal-title
      margin 0
    .close-button
      margin-left auto
      cursor pointer
  .modal__footer
    display flex
    margin $space-3 0
    padding 0 $space-3
  .modal__content
    flex 1
    padding $space-3
    .device-select
      padding-top $space-2
      &--header
        display flex
        flex-direction row
        &__filter
          margin-left auto
      &--name
        font-size $fs-h2
    .sum-profile
      display flex
      flex-direction row
      justify-content space-between
      padding $space-2
      font-size $fs-h3

  .buttons
    margin-left auto

.profile-name
  padding-left $space-3

.text-field
  display flex
  flex-direction column
  &--read-only
    .text-field__input
      background-color $light
      pointer-events none
  .text-field__label
    margin-bottom $space-1
    font-size $fs-h1
    &--hint
      margin-bottom 0px
  .text-field__required
    color $negative
    font-size $fs-h1
  .text-field__hint
    margin-bottom $space-1
    color $infos
    font-size $fs-body
  .text-field__input
    margin-bottom 'calc(%s + %s)' % ($space-little 15px)
    font-size $fs-h2
    &--error
      margin-bottom $space-little
  .text-field__error-message
    color $negative

