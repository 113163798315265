

















































































































































@import '~variables'

.add-device-modal >>> .modal-content
  display flex
  flex-direction column
  overflow visible
  min-width 500px
  max-width @min-width
  .modal__header
    display flex
    align-items center
    padding $space-2 $space-3
    background-color black
    color white
    .modal-title
      margin 0
      line-height 1
    .close-button
      margin-left auto
      cursor pointer
  .modal__content
    flex 1
    overflow-y auto
    padding $space-3
    .form-add-device
      .add-button
        display block
        margin-left auto
    .text-field, .type-select
      margin-bottom $space-1
