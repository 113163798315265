@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/Lato/Light/Lato-Light.ttf") format('truetype'), url("/fonts/Lato/Light/Lato-Light.woff2") format('woff2'), url("/fonts/Lato/Light/Lato-Light.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Lato/Regular/Lato-Regular.ttf") format('truetype'), url("/fonts/Lato/Regular/Lato-Regular.woff2") format('woff2'), url("/fonts/Lato/Regular/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Lato/Bold/Lato-Bold.ttf") format('truetype'), url("/fonts/Lato/Bold/Lato-Bold.woff2") format('woff2'), url("/fonts/Lato/Bold/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/Lato/Black/Lato-Black.ttf") format('truetype'), url("/fonts/Lato/Black/Lato-Black.woff2") format('woff2'), url("/fonts/Lato/Black/Lato-Black.woff") format('woff');
}
.sum-item {
  display: flex;
  flex-direction: column;
  background-color: #fff;
}
.sum-item .sum-item__header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #ddd9d3;
  cursor: pointer;
}
.sum-item .sum-item__header .sum-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 61px;
  user-select: none;
}
.sum-item .sum-item__header .sum-info .sum-name {
  flex: 2;
  padding-left: 16px;
  height: 100%;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1.3rem;
  line-height: 61px;
}
.sum-item .sum-item__header .sum-info .sum-actions .sum-menu__title {
  position: relative;
  margin-right: 8px;
  font-size: 1.3rem;
}
.sum-item .sum-item__header .sum-info .sum-actions .sum-menu__title .sum-menu-list {
  position: absolute;
  top: 100%;
  right: 8px;
  z-index: 10;
  padding: 0;
  width: 130px;
  border-radius: 4px;
  background-color: #fff;
}
.sum-item .sum-item__header .sum-info .sum-actions .sum-menu__title .sum-menu-list .q-list {
  padding: 0;
  border: unset;
}
.sum-item .sum-item__header .sum-info .sum-actions .sum-menu__title .sum-menu-list .q-list .q-item {
  font-weight: 400;
}
.sum-item .sum-item__header .sum-info .sum-actions .sum-menu__title .sum-menu-list .q-list .q-item:hover {
  background-color: #b4b2a9;
}
.sum-item .sum-item__header .sum-info .sum-actions .sum-menu__title .sum-menu-list .q-list .q-item:after {
  left: 0;
}
.sum-item .sum-item__header .sum-info .sum-actions .sum-menu__title .sum-menu-list .q-list .item-delete {
  color: #e91e63;
}
.sum-item .sum-item__header .sum-info .sum-close {
  margin-right: 10px;
  cursor: pointer;
}
.sum-item .sum-item__main .header,
.sum-item .sum-item__body .header {
  display: flex;
  align-items: center;
  padding: 16px;
  background-color: #f4f4f4;
}
.sum-item .sum-item__main .header .select-label,
.sum-item .sum-item__body .header .select-label {
  font-size: 1.3rem;
}
.sum-item .sum-item__main .header .o-select,
.sum-item .sum-item__body .header .o-select {
  margin-left: 8px;
  width: 250px;
}
.sum-item .sum-item__main .header .period-selector,
.sum-item .sum-item__body .header .period-selector {
  margin-left: auto;
  width: fit-content;
  margin: 0 100px;
}
.sum-item .sum-item__main .total,
.sum-item .sum-item__body .total {
  padding: 16px;
  background-color: #f4f4f4;
  font-size: 1.3rem;
}
.sum-item .sum-item__main .graph,
.sum-item .sum-item__body .graph {
  padding: 16px;
}
.sum-item .sum-item__main .graph .graph-info,
.sum-item .sum-item__body .graph .graph-info {
  display: flex;
  justify-content: center;
  padding: 8px;
  font-weight: 300;
  font-size: 1.1rem;
}
.sum-item .sum-item__main .data-table,
.sum-item .sum-item__body .data-table {
  margin-bottom: 46px;
  padding: 16px;
  padding-top: 0;
}
.sum-item .sum-item__footer {
  display: flex;
  justify-content: center;
  padding: 16px;
}
.sum-item .sum__slot {
  min-height: 400px;
  background-color: #f7f7f5;
}
.collapse-transition {
  overflow: hidden;
  transition: max-height 0.3s ease 0.2s;
}
.fade-enter-active,
.fade-leave-active {
  max-height: 4000px;
}
.fade-enter,
.fade-leave-to {
  max-height: 0px;
  transition: max-height 0.3s cubic-bezier(0, 1, 0, 1) 0s;
}
@media screen and (max-width: 500px) {
  .fade-enter,
  .fade-leave-to {
    transition: none;
  }
}
/*# sourceMappingURL=src/app/pages/data/components/sum-item.css.map */