

























































































































































































































@import '~variables'

.sidebar-data
  overflow-y auto
  height 100%
  background-color white
  .fetching-loader
    display flex
    flex-direction column
    justify-content center
    align-items center
    height 100%
    .fetching-text
      letter-spacing 2px
      font-weight 700
      font-size $fs-h1
  .header
    display flex
    align-items center
    padding $space-2
    background-color $dash-light
    .select-label
      font-size $fs-h2
    .select-field
      margin-left $space-1
      width 250px
    .period-selector
      margin-left auto
      width fit-content
      margin 0 100px
  .no-data
    padding $space-3
    text-align center
    letter-spacing 2px
    font-weight 700
    font-size $fs-h1
  .graph
    padding $space-2
    .graph-info
      display flex
      justify-content center
      padding $space-1
      font-weight 300
      font-size $fs-h3
  .data-table
    margin-bottom 46px
    padding $space-2
    padding-top 0
