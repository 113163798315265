


















































































































































































































































































@import '~variables'

.sum-item
  display flex
  flex-direction column
  background-color white
  .sum-item__header
    display flex
    justify-content flex-start
    align-items center
    width 100%
    border-bottom $border-light solid $silver
    cursor pointer
    .sum-info
      display flex
      justify-content space-between
      align-items center
      width 100%
      height 61px
      user-select none
      .sum-name
        flex 2
        padding-left 16px
        height 100%
        text-transform uppercase
        font-weight 700
        font-size $fs-h2
        line-height 61px
      .sum-actions
        .sum-menu__title
          position relative
          margin-right $space-1
          font-size $fs-h2
          .sum-menu-list
            position absolute
            top 100%
            right $space-1
            z-index 10
            padding 0
            width 130px
            border-radius $border-radius-smooth
            background-color white
            .q-list
              padding 0
              border unset
              .q-item
                font-weight 400
                &:hover
                  background-color $grey
                &:after
                  left 0 // make the separator line full width
              .item-delete
                color $negative
      .sum-close
        margin-right 10px
        cursor pointer
  .sum-item__main, .sum-item__body
    .header
      display flex
      align-items center
      padding $space-2
      background-color $dash-light
      .select-label
        font-size $fs-h2
      .o-select
        margin-left $space-1
        width 250px
      .period-selector
        margin-left auto
        width fit-content
        margin 0 100px
    .total
      padding $space-2
      background-color $dash-light
      font-size $fs-h2
    .graph
      padding $space-2
      .graph-info
        display flex
        justify-content center
        padding $space-1
        font-weight 300
        font-size $fs-h3
    .data-table
      margin-bottom 46px
      padding $space-2
      padding-top 0
  .sum-item__footer
    display flex
    justify-content center
    padding 16px
  .sum__slot
    min-height 400px
    background-color #f7f7f5

// VUE.js transition classes
.collapse-transition
  overflow hidden
  transition max-height 0.3s ease 0.2s

.fade-enter-active, .fade-leave-active
  max-height 4000px

.fade-enter, .fade-leave-to
  max-height 0px
  transition max-height 0.3s cubic-bezier(0, 1, 0, 1) 0s
  @media screen and (max-width: 500px)
    transition none
