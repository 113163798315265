@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/Lato/Light/Lato-Light.ttf") format('truetype'), url("/fonts/Lato/Light/Lato-Light.woff2") format('woff2'), url("/fonts/Lato/Light/Lato-Light.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Lato/Regular/Lato-Regular.ttf") format('truetype'), url("/fonts/Lato/Regular/Lato-Regular.woff2") format('woff2'), url("/fonts/Lato/Regular/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Lato/Bold/Lato-Bold.ttf") format('truetype'), url("/fonts/Lato/Bold/Lato-Bold.woff2") format('woff2'), url("/fonts/Lato/Bold/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/Lato/Black/Lato-Black.ttf") format('truetype'), url("/fonts/Lato/Black/Lato-Black.woff2") format('woff2'), url("/fonts/Lato/Black/Lato-Black.woff") format('woff');
}
.sidebar-node {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.sidebar-node .node-infos {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-top: 16px;
  padding: 16px;
}
.sidebar-node .node-infos .info-row {
  display: flex;
  align-items: flex-end;
  margin-bottom: 16px;
}
.sidebar-node .node-infos .info-value--name {
  margin: 0;
  font-weight: 700;
  font-size: 2rem;
  line-height: 1;
}
.sidebar-node .node-infos .info-value--node-type {
  margin-right: 8px;
  margin-left: 16px;
  text-transform: uppercase;
  font-size: 1.1rem;
}
.sidebar-node .node-infos .info-value--uuid {
  color: #848173;
  font-size: 1.1rem;
}
.sidebar-node .node-actions {
  display: flex;
  justify-content: flex-start;
  padding: 16px;
  background-color: #fff;
}
.sidebar-node .node-actions .o-button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
  width: fit-content;
}
/*# sourceMappingURL=src/app/pages/data/components/sidebar-node.css.map */