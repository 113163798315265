




























































































































@import '~variables'

.sidebar-in
  position relative
  display flex
  flex-direction column
  height 100%
  .sidebar-item:last-child
    border-bottom none
  .sidebar-in__actions
    display flex
    justify-content flex-end
    margin-top auto
    padding $space-1
    .action
      display flex
      justify-content center
      align-items center
      padding $space-little
      background-color $light
      box-shadow $shadow-elevation-1
      cursor pointer
      transition box-shadow 0.3s ease
      &:hover
        box-shadow $shadow-elevation-2
      .action__icon
        transform rotate(90deg)
        fill $grey
