@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/Lato/Light/Lato-Light.ttf") format('truetype'), url("/fonts/Lato/Light/Lato-Light.woff2") format('woff2'), url("/fonts/Lato/Light/Lato-Light.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Lato/Regular/Lato-Regular.ttf") format('truetype'), url("/fonts/Lato/Regular/Lato-Regular.woff2") format('woff2'), url("/fonts/Lato/Regular/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Lato/Bold/Lato-Bold.ttf") format('truetype'), url("/fonts/Lato/Bold/Lato-Bold.woff2") format('woff2'), url("/fonts/Lato/Bold/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/Lato/Black/Lato-Black.ttf") format('truetype'), url("/fonts/Lato/Black/Lato-Black.woff2") format('woff2'), url("/fonts/Lato/Black/Lato-Black.woff") format('woff');
}
.filter-selection {
  position: relative;
  width: 100%;
}
.filter-selection .o-label {
  display: block;
  margin: 0;
  padding: 16px;
  font-weight: 300;
  font-size: 1.1rem;
}
.filter-selection .tags-drawer {
  position: absolute;
  top: -40px;
  left: 100%;
  transition: all 0.3s ease;
}
.filter-selection .filter-list {
  display: flex;
  flex-direction: column;
  min-height: 40px;
}
.filter-selection .filter-list .filter {
  display: flex;
  flex-direction: column;
  padding: 16px;
  padding-bottom: 8px;
}
.filter-selection .filter-list .filter .filter__title {
  margin: 0;
  margin-bottom: 16px;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1;
}
.filter-selection .filter-list .filter .filter__values {
  display: flex;
  flex-wrap: wrap;
}
.filter-selection .filter-list .filter .filter__values .o-button {
  display: flex;
  margin-right: 8px;
  margin-bottom: 8px;
  padding: 0;
  height: auto;
  border-radius: 20px;
  background-color: #fff;
  font-weight: 400;
}
.filter-selection .filter-list .filter .filter__values .o-button .o-button__content {
  padding: 4px 8px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}
.filter-selection .filter-list .filter .filter__values .o-button .o-button__side-info {
  padding: 4px 16px;
  height: 100%;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  background-color: #f3f1ef;
  color: #464749;
}
.filter-selection .filter-list .filter .filter__values .o-button:last-child {
  margin-right: 0;
}
.filter-selection .filter-list .filter .filter__values .o-button--black {
  background-color: #fff !important;
}
.filter-selection .filter-list .filter .filter__values .o-button--black .o-button__content {
  background-color: #000;
}
.fade-enter-active,
.fade-leave-active {
  opacity: 1;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
/*# sourceMappingURL=src/app/pages/data/components/filter-selection.css.map */