







































@import '~variables'

.selection-info
  position relative
  flex 2
  user-select none
  &--extended
    display flex
  .data-info
    display flex
    &--extended
      margin-right 30px
    .data-info__values, .data-info__labels
      display flex
      flex-direction column
      justify-content flex-start
      font-size $fs-h3
    .data-info__values
      display flex
      justify-content center
      text-align right
      font-weight 700
    .data-info__labels
      display flex
      flex 6
      justify-content center
      margin-left 8px
      letter-spacing 0.5px
      &--extended
        padding-right 30px
  .selection__title
    position absolute
    top 0
    right $space-2
    height 100%
    content ''
    text-align center
    letter-spacing 1.5px
    writing-mode vertical-rl
    text-orientation sideways-left
