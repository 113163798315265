











































































































































































@import '~variables'

.nav-tabs
  position fixed
  bottom -250px
  left 0
  z-index 3000
  min-height 300px
  width 100%
  transition all 0.2s ease

.nav-tabs-opened
  bottom 0 !important
  transition all 0.2s ease

.nav-tabs-full
  top 35px !important
  height 100%

.tabs
  z-index 1000
  width 100%
  height 50px
  background-color white
  .tab
    display flex
    justify-content center
    align-items center
    height 100%
    background-image linear-gradient(to right, $silver, #f7f3ef 50%)
    color #464749
    font-weight 700
    font-size $fs-h2
  .tab-black
    background-color black
    background-image none
    color white
  .tab-selected
    background-color white
    background-image none
    color black

.tab-content
  z-index 1000
  overflow auto
  overflow-x hidden
  padding-top 20px
  width 100%
  height 250px
  background-color white
  .oapp-item
    visibility visible
    max-height 2000px
    opacity 1
    transition max-height 0.5s cubic-bezier(1, 0, 1, 0) 0s, visibility 0s linear 0s, opacity 0.2s linear 0.5s
  .oapp-item-hidden
    visibility hidden
    max-height 0px
    opacity 0
    transition max-height 0.6s cubic-bezier(0, 1, 0, 1) 0s, visibility 0s linear 0.5s, opacity 0.2s linear 0s

.tab-content-full
  height 100%

.clickaway-helper, .clickaway-helper--header
  position fixed
  top 0
  bottom 0
  z-index 700
  width 100%
  height 100%
  background-color transparent

.clickaway-helper--header
  z-index 2000
  height 35px
