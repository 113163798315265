@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/Lato/Light/Lato-Light.ttf") format('truetype'), url("/fonts/Lato/Light/Lato-Light.woff2") format('woff2'), url("/fonts/Lato/Light/Lato-Light.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Lato/Regular/Lato-Regular.ttf") format('truetype'), url("/fonts/Lato/Regular/Lato-Regular.woff2") format('woff2'), url("/fonts/Lato/Regular/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Lato/Bold/Lato-Bold.ttf") format('truetype'), url("/fonts/Lato/Bold/Lato-Bold.woff2") format('woff2'), url("/fonts/Lato/Bold/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/Lato/Black/Lato-Black.ttf") format('truetype'), url("/fonts/Lato/Black/Lato-Black.woff2") format('woff2'), url("/fonts/Lato/Black/Lato-Black.woff") format('woff');
}
.add-device-modal >>> .modal-content {
  display: flex;
  flex-direction: column;
  overflow: visible;
  min-width: 500px;
  max-width: 500px;
}
.add-device-modal >>> .modal-content .modal__header {
  display: flex;
  align-items: center;
  padding: 16px 32px;
  background-color: #000;
  color: #fff;
}
.add-device-modal >>> .modal-content .modal__header .modal-title {
  margin: 0;
  line-height: 1;
}
.add-device-modal >>> .modal-content .modal__header .close-button {
  margin-left: auto;
  cursor: pointer;
}
.add-device-modal >>> .modal-content .modal__content {
  flex: 1;
  overflow-y: auto;
  padding: 32px;
}
.add-device-modal >>> .modal-content .modal__content .form-add-device .add-button {
  display: block;
  margin-left: auto;
}
.add-device-modal >>> .modal-content .modal__content .text-field,
.add-device-modal >>> .modal-content .modal__content .type-select {
  margin-bottom: 8px;
}
/*# sourceMappingURL=src/app/pages/data/components/add-device-modal.css.map */