
































































































































@import '~variables'

.address-group
  .field-container
    padding $space-2
    border-radius $border-radius-smooth
    background-color $dash-light
