@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/Lato/Light/Lato-Light.ttf") format('truetype'), url("/fonts/Lato/Light/Lato-Light.woff2") format('woff2'), url("/fonts/Lato/Light/Lato-Light.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Lato/Regular/Lato-Regular.ttf") format('truetype'), url("/fonts/Lato/Regular/Lato-Regular.woff2") format('woff2'), url("/fonts/Lato/Regular/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Lato/Bold/Lato-Bold.ttf") format('truetype'), url("/fonts/Lato/Bold/Lato-Bold.woff2") format('woff2'), url("/fonts/Lato/Bold/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/Lato/Black/Lato-Black.ttf") format('truetype'), url("/fonts/Lato/Black/Lato-Black.woff2") format('woff2'), url("/fonts/Lato/Black/Lato-Black.woff") format('woff');
}
.control-time {
  padding: 8px;
  width: 100%;
}
.control-time .button-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 16px;
  border-radius: 10px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}
.control-time .button-container .o-button {
  flex: 1;
  background-color: #fff;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 0.8rem;
}
.control-time .button-container .o-button--black {
  font-weight: 700;
}
.control-time .button-container .o-button--hover:hover {
  background-color: #000;
  color: #fff;
  font-weight: 700;
}
.control-time .button-container .button-container__top {
  display: flex;
  margin-bottom: 2px;
}
.control-time .button-container .button-container__top .o-button {
  margin-left: 2px;
  padding: 8px;
}
.control-time .button-container .button-container__top .o-button--year {
  margin-left: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.control-time .button-container .button-container__top .o-button--month,
.control-time .button-container .button-container__top .o-button--week {
  border-radius: 0;
}
.control-time .button-container .button-container__top .o-button--day {
  border-top-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.control-time .button-container .button-container__bottom {
  display: flex;
}
.control-time .button-container .button-container__bottom .o-button--live {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.control-time .date-input-container {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.1rem;
}
.control-time .date-input-container .control-time__date-input {
  padding: 4px;
  font-weight: 700;
  cursor: pointer;
  user-select: none;
}
.control-time .date-input-container .date-input__chevron {
  transform: rotate(-90deg);
}
.control-time .date-input-container .date-input__hours {
  padding: 4px;
  color: #464749;
  font-weight: 300;
  cursor: pointer;
  user-select: none;
}
.control-time .date-input-container .selectedDrawer {
  height: 23px;
  border-radius: 4px;
  background-color: #000;
  color: #fff;
}
.control-time .calendar-drawer {
  position: absolute;
  top: 23px;
  left: 100%;
  transition: all 0.3s ease;
}
@media screen and (max-width: 500px) {
  .control-time .calendar-drawer {
    top: 170px;
    right: 0;
    left: 0;
    z-index: 100;
    margin: 0 auto;
  }
}
.fade-enter-active,
.fade-leave-active {
  opacity: 1;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
/*# sourceMappingURL=src/app/pages/data/components/time.css.map */