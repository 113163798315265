
























































@import '~variables'

.building-control
  padding $space-2 $space-1
  width 100%
  .o-input
    margin-bottom 16px
    width 100%

.building-list
  display flex
  flex-direction column
  .building-item
    margin-bottom $space-little
    &:last-child
      margin-bottom 0

.building-control__selection
  display flex
  margin-top 16px
  color $dark
