@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/Lato/Light/Lato-Light.ttf") format('truetype'), url("/fonts/Lato/Light/Lato-Light.woff2") format('woff2'), url("/fonts/Lato/Light/Lato-Light.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Lato/Regular/Lato-Regular.ttf") format('truetype'), url("/fonts/Lato/Regular/Lato-Regular.woff2") format('woff2'), url("/fonts/Lato/Regular/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Lato/Bold/Lato-Bold.ttf") format('truetype'), url("/fonts/Lato/Bold/Lato-Bold.woff2") format('woff2'), url("/fonts/Lato/Bold/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/Lato/Black/Lato-Black.ttf") format('truetype'), url("/fonts/Lato/Black/Lato-Black.woff2") format('woff2'), url("/fonts/Lato/Black/Lato-Black.woff") format('woff');
}
.selection-info {
  position: relative;
  flex: 2;
  user-select: none;
}
.selection-info--extended {
  display: flex;
}
.selection-info .data-info {
  display: flex;
}
.selection-info .data-info--extended {
  margin-right: 30px;
}
.selection-info .data-info .data-info__values,
.selection-info .data-info .data-info__labels {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-size: 1.1rem;
}
.selection-info .data-info .data-info__values {
  display: flex;
  justify-content: center;
  text-align: right;
  font-weight: 700;
}
.selection-info .data-info .data-info__labels {
  display: flex;
  flex: 6;
  justify-content: center;
  margin-left: 8px;
  letter-spacing: 0.5px;
}
.selection-info .data-info .data-info__labels--extended {
  padding-right: 30px;
}
.selection-info .selection__title {
  position: absolute;
  top: 0;
  right: 16px;
  height: 100%;
  content: '';
  text-align: center;
  letter-spacing: 1.5px;
  writing-mode: vertical-rl;
  text-orientation: sideways-left;
}
/*# sourceMappingURL=src/app/pages/data/components/selection-info.css.map */