@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/Lato/Light/Lato-Light.ttf") format('truetype'), url("/fonts/Lato/Light/Lato-Light.woff2") format('woff2'), url("/fonts/Lato/Light/Lato-Light.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Lato/Regular/Lato-Regular.ttf") format('truetype'), url("/fonts/Lato/Regular/Lato-Regular.woff2") format('woff2'), url("/fonts/Lato/Regular/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Lato/Bold/Lato-Bold.ttf") format('truetype'), url("/fonts/Lato/Bold/Lato-Bold.woff2") format('woff2'), url("/fonts/Lato/Bold/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/Lato/Black/Lato-Black.ttf") format('truetype'), url("/fonts/Lato/Black/Lato-Black.woff2") format('woff2'), url("/fonts/Lato/Black/Lato-Black.woff") format('woff');
}
.widget-modal >>> .modal-content {
  display: flex;
  flex-direction: column;
  padding-bottom: 32px;
  width: 750px;
  height: 900px;
}
.widget-modal >>> .modal-content .modal__header {
  display: flex;
  align-items: center;
  margin: 32px 0;
  padding: 0 32px;
}
.widget-modal >>> .modal-content .modal__header .modal-title {
  margin: 0;
}
.widget-modal >>> .modal-content .modal__header .close-button {
  margin-left: auto;
  cursor: pointer;
}
.widget-modal >>> .modal-content .modal__footer {
  display: flex;
  margin: 32px 0;
  padding: 0 32px;
}
.widget-modal >>> .modal-content .modal__content {
  flex: 1;
  padding: 32px;
}
.widget-modal >>> .modal-content .modal__content .device-select {
  padding-top: 16px;
}
.widget-modal >>> .modal-content .modal__content .device-select--header {
  display: flex;
  flex-direction: row;
}
.widget-modal >>> .modal-content .modal__content .device-select--header__filter {
  margin-left: auto;
}
.widget-modal >>> .modal-content .modal__content .device-select--name {
  font-size: 1.3rem;
}
.widget-modal >>> .modal-content .modal__content .sum-profile {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px;
  font-size: 1.1rem;
}
.widget-modal >>> .modal-content .buttons {
  margin-left: auto;
}
.profile-name {
  padding-left: 32px;
}
.text-field {
  display: flex;
  flex-direction: column;
}
.text-field--read-only .text-field__input {
  background-color: #f3f1ef;
  pointer-events: none;
}
.text-field .text-field__label {
  margin-bottom: 8px;
  font-size: 1.6rem;
}
.text-field .text-field__label--hint {
  margin-bottom: 0px;
}
.text-field .text-field__required {
  color: #e91e63;
  font-size: 1.6rem;
}
.text-field .text-field__hint {
  margin-bottom: 8px;
  color: #8a8686;
  font-size: 1rem;
}
.text-field .text-field__input {
  margin-bottom: calc(4px + 15px);
  font-size: 1.3rem;
}
.text-field .text-field__input--error {
  margin-bottom: 4px;
}
.text-field .text-field__error-message {
  color: #e91e63;
}
/*# sourceMappingURL=src/app/pages/data/components/sum-creation.css.map */