











































































































































































































@import '~variables'

.zone-selection
  position relative
  display flex
  flex-direction column
  padding $space-2 0
  padding-bottom 0
  width 100%
  .input-container
    margin-bottom $space-2
    padding 0 $space-1
    .search-input
      width 100%
  .parcels-list
    position relative
    display flex
    flex-direction column
    overflow-y auto
    padding 0 $space-1
    .zone-item
      flex-shrink 0
      margin-bottom $space-little
      &:last-child
        margin-bottom $space-2
