

































































































































































































































@import '~variables'

.zone-item
  position relative
  display flex
  align-items center
  &--hover
    background-color $light
  .drawer
    position fixed
    right 100%
  .zone-item__form
    margin 16px
  .zone-info
    display flex
    margin $space-little 0
    margin-left $space-2
    padding 0
    width 60%
    height auto
    border-radius $border-radius-rounded
    background-color white
    .zone-info__name
      display flex
      flex 2
      justify-content space-between
      align-items center
      padding $space-little
      padding-left $space-2
      width 100%
      border-top-left-radius $border-radius-rounded
      border-bottom-left-radius $border-radius-rounded
      font-weight $fw-regular
      font-size $fs-body
    .zone-info__sublabel
      display flex
      flex 1
      justify-content center
      align-items center
      padding $space-little $space-1
      background-color $light
      text-align right
      font-weight $fw-light
      font-size $fs-little
    .zone-info__color
      padding $space-little
      width 20%
      height 100%
      height 100%
      border-top-right-radius $border-radius-rounded
      border-bottom-right-radius $border-radius-rounded
      cursor pointer
      &--editing
        border-right 2px solid black
  .zone-action
    display flex
    flex 1
    justify-content center
    align-items center
    user-select none
    span
      margin-right 4px
      &:last-child
        margin-right 0
    .zone-action__separator
      width 1px
      height 12px
      background-color black
    .zone-action__action
      padding 1px
      cursor pointer
      &:hover
        font-weight 700

.zone-item--active
  .zone-info__name
    background-color $black
    color white
  .zone-info__sublabel
    background-color $dark !important
    color white

.zone-item--chip
  width 100%
  .zone-info
    margin 0
    width 100%

.fade-enter-active, .fade-leave-active
  opacity 1

.fade-enter, .fade-leave-to
  opacity 0
