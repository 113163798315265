




































































@import '~variables'

.parcel-form
  .form__input
    margin-bottom 15px
  .select
    margin-bottom 16px
  .form__submit
    margin 0 auto
    width 50%
    background-color white
    color black
    text-align center
