



















































































@import '~variables'

.sidebar-calc
  overflow-y auto
  padding-bottom $space-3
  height 100%
  background-color white
  .sum-item
    visibility visible
    max-height 4000px
    opacity 1
    transition max-height 0.5s cubic-bezier(1, 0, 1, 0) 0s, visibility 0s linear 0s, opacity 0.2s linear 0.5s
  .sum-item-hidden
    visibility hidden
    max-height 0px
    opacity 0
    transition max-height 0.6s cubic-bezier(0, 1, 0, 1) 0s, visibility 0s linear 0.5s, opacity 0.2s linear 0s
  .add-button
    margin 20px
    padding 10px 15px
    border-width thin
    border-style solid
    border-color black
    border-radius 20px
    background-color white
    color $dark
    text-align center
    text-transform uppercase
    font-weight 700
    font-size $fs-h1
    cursor pointer
    transition opacity 1.5s ease-in
    &--hidden
      visibility hidden
      max-height 0px
      opacity 0
      transition max-height 0.6s cubic-bezier(0, 1, 0, 1) 0s, visibility 0s linear 0.5s, opacity 0.2s linear 0s
