






















































































































@import '~variables'

.home
  min-height auto
  height 100%
  .home__sidebar.no-transition >>> aside
    transform translateX(0px)
  .home__sidebar--left >>> aside
    overflow visible // to see the drawer
  .home__sidebar--right
    z-index 2
  .home__center
    z-index 1
    padding-right 350px
    width 100%
    height 100%
    transition padding-right 0.3s ease
    &--sr-hidden
      padding-right 0
    &--sr-small
      padding-right 350px
    &--sr-medium, &--sr-large
      padding-right calc((100vw / 3))
    @media screen and (max-width: 500px), (max-height: 500px)
      overflow hidden
      padding-right 0
    .viewer
      position relative
      height 100%
      background-color $silver
    .visualisation-control
      position absolute
      top $space-2
      left @top
      z-index 2
      transition left 0.3s ease
      &--sided
        left 110px
