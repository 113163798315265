





























































@import '~variables'

.global-viewer
  padding 0.1px
  width 100%
  height 100%
  .nav-alert
    margin $space-2
