@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/Lato/Light/Lato-Light.ttf") format('truetype'), url("/fonts/Lato/Light/Lato-Light.woff2") format('woff2'), url("/fonts/Lato/Light/Lato-Light.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Lato/Regular/Lato-Regular.ttf") format('truetype'), url("/fonts/Lato/Regular/Lato-Regular.woff2") format('woff2'), url("/fonts/Lato/Regular/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Lato/Bold/Lato-Bold.ttf") format('truetype'), url("/fonts/Lato/Bold/Lato-Bold.woff2") format('woff2'), url("/fonts/Lato/Bold/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/Lato/Black/Lato-Black.ttf") format('truetype'), url("/fonts/Lato/Black/Lato-Black.woff2") format('woff2'), url("/fonts/Lato/Black/Lato-Black.woff") format('woff');
}
.sidebar-in {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.sidebar-in .sidebar-item:last-child {
  border-bottom: none;
}
.sidebar-in .sidebar-in__actions {
  display: flex;
  justify-content: flex-end;
  margin-top: auto;
  padding: 8px;
}
.sidebar-in .sidebar-in__actions .action {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  background-color: #f3f1ef;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  cursor: pointer;
  transition: box-shadow 0.3s ease;
}
.sidebar-in .sidebar-in__actions .action:hover {
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}
.sidebar-in .sidebar-in__actions .action .action__icon {
  transform: rotate(90deg);
  fill: #b4b2a9;
}
/*# sourceMappingURL=src/app/pages/data/views/sidebar-in.css.map */