























































@import '~variables'

.color-picker
  padding 8px
  .title
    margin 0
    margin-bottom 8px
    font-weight 700
    font-size $fs-h3
    line-height 1
  .color-list
    display flex
    height 70px
    background-color blue
    .color-item
      flex 1
      height 100%
      cursor pointer
      transition transform 0.2s ease
      &:hover
        transform scale(1.2)
