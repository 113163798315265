












@import '~variables'

.info-item-container
  padding $space-2
  border-right $border-light solid $light
