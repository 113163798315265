@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/Lato/Light/Lato-Light.ttf") format('truetype'), url("/fonts/Lato/Light/Lato-Light.woff2") format('woff2'), url("/fonts/Lato/Light/Lato-Light.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Lato/Regular/Lato-Regular.ttf") format('truetype'), url("/fonts/Lato/Regular/Lato-Regular.woff2") format('woff2'), url("/fonts/Lato/Regular/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Lato/Bold/Lato-Bold.ttf") format('truetype'), url("/fonts/Lato/Bold/Lato-Bold.woff2") format('woff2'), url("/fonts/Lato/Bold/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/Lato/Black/Lato-Black.ttf") format('truetype'), url("/fonts/Lato/Black/Lato-Black.woff2") format('woff2'), url("/fonts/Lato/Black/Lato-Black.woff") format('woff');
}
.tag-filter {
  padding: 16px;
}
.tag-filter .tag-filter__header {
  margin-bottom: 16px;
  width: 110px;
}
.tag-filter .tag-filter__header .header-title {
  margin: 0;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1;
}
.tag-filter .tag-filter__header .header-subtitle {
  font-size: 1rem;
}
.tag-filter .tag-list {
  display: flex;
  flex-direction: column;
}
.tag-filter .tag-list .tag-list__item-container {
  margin-bottom: 4px;
  width: 100%;
}
.tag-filter .tag-list .tag-list__item-container:last-child {
  margin-bottom: 0;
}
/*# sourceMappingURL=src/app/pages/data/components/prop-selection.css.map */