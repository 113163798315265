


































































































































































































































































@import '~variables'

.control-time
  padding $space-1
  width 100%
  .button-container
    display flex
    flex-direction column
    justify-content center
    margin-bottom $space-2
    border-radius $border-radius
    box-shadow $shadow-elevation-1
    .o-button
      flex 1
      background-color white
      text-transform uppercase
      font-weight $fw-regular
      font-size $fs-little
    .o-button--black
      font-weight $fw-bold
    .o-button--hover:hover
      background-color black
      color white
      font-weight $fw-bold
    .button-container__top
      display flex
      margin-bottom $border-medium
      .o-button
        margin-left $border-medium
        padding $space-1
        &--year
          margin-left 0
          border-top-right-radius 0
          border-bottom-right-radius 0
          border-bottom-left-radius 0
        &--month, &--week
          border-radius 0
        &--day
          border-top-left-radius 0
          border-bottom-right-radius 0
          border-bottom-left-radius 0
    .button-container__bottom
      display flex
      .o-button--live
        border-top-left-radius 0
        border-top-right-radius 0
  .date-input-container
    display flex
    justify-content center
    align-items center
    font-size $nfs-body
    .control-time__date-input
      padding $space-little
      font-weight $fw-bold
      cursor pointer
      user-select none
    .date-input__chevron
      transform rotate(-90deg)
    .date-input__hours
      padding $space-little
      color $dark
      font-weight $fw-light
      cursor pointer
      user-select none
    .selectedDrawer
      height 23px
      border-radius 4px
      background-color black
      color white
  .calendar-drawer
    position absolute
    top 23px
    left 100%
    transition all 0.3s ease
    @media screen and (max-width: 500px)
      top 170px
      right 0
      left 0
      z-index 100
      margin 0 auto

.fade-enter-active, .fade-leave-active
  opacity 1

.fade-enter, .fade-leave-to
  opacity 0
