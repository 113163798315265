@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/Lato/Light/Lato-Light.ttf") format('truetype'), url("/fonts/Lato/Light/Lato-Light.woff2") format('woff2'), url("/fonts/Lato/Light/Lato-Light.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Lato/Regular/Lato-Regular.ttf") format('truetype'), url("/fonts/Lato/Regular/Lato-Regular.woff2") format('woff2'), url("/fonts/Lato/Regular/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Lato/Bold/Lato-Bold.ttf") format('truetype'), url("/fonts/Lato/Bold/Lato-Bold.woff2") format('woff2'), url("/fonts/Lato/Bold/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/Lato/Black/Lato-Black.ttf") format('truetype'), url("/fonts/Lato/Black/Lato-Black.woff2") format('woff2'), url("/fonts/Lato/Black/Lato-Black.woff") format('woff');
}
.home {
  min-height: auto;
  height: 100%;
}
.home .home__sidebar.no-transition >>> aside {
  transform: translateX(0px);
}
.home .home__sidebar--left >>> aside {
  overflow: visible;
}
.home .home__sidebar--right {
  z-index: 2;
}
.home .home__center {
  z-index: 1;
  padding-right: 350px;
  width: 100%;
  height: 100%;
  transition: padding-right 0.3s ease;
}
.home .home__center--sr-hidden {
  padding-right: 0;
}
.home .home__center--sr-small {
  padding-right: 350px;
}
.home .home__center--sr-medium,
.home .home__center--sr-large {
  padding-right: calc((100vw / 3));
}
@media screen and (max-width: 500px), (max-height: 500px) {
  .home .home__center {
    overflow: hidden;
    padding-right: 0;
  }
}
.home .home__center .viewer {
  position: relative;
  height: 100%;
  background-color: #ddd9d3;
}
.home .home__center .visualisation-control {
  position: absolute;
  top: 16px;
  left: 16px;
  z-index: 2;
  transition: left 0.3s ease;
}
.home .home__center .visualisation-control--sided {
  left: 110px;
}
/*# sourceMappingURL=src/app/pages/data/index.css.map */