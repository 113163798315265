
















































































































@import '~variables'

.filter-selection
  position relative
  width 100%
  .o-label
    display block
    margin 0
    padding $space-2
    font-weight $fw-light
    font-size $nfs-body
  .tags-drawer
    position absolute
    top -40px
    left 100%
    transition all 0.3s ease
  .filter-list
    display flex
    flex-direction column
    min-height 40px
    .filter
      display flex
      flex-direction column
      padding 16px
      padding-bottom 8px
      .filter__title
        margin 0
        margin-bottom 16px
        font-weight $fw-regular
        font-size $nfs-h3
        line-height 1
      .filter__values
        display flex
        flex-wrap wrap
        .o-button
          display flex
          margin-right 8px
          margin-bottom 8px
          padding 0
          height auto
          border-radius $border-radius-rounded
          background-color white
          font-weight $fw-regular
          .o-button__content
            padding $space-little $space-1
            border-top-left-radius $border-radius-rounded
            border-bottom-left-radius $border-radius-rounded
          .o-button__side-info
            padding $space-little $space-2
            height 100%
            border-top-right-radius $border-radius-rounded
            border-bottom-right-radius $border-radius-rounded
            background-color $light
            color $dark
          &:last-child
            margin-right 0
        .o-button--black
          background-color white !important
          .o-button__content
            background-color $black

.fade-enter-active, .fade-leave-active
  opacity 1

.fade-enter, .fade-leave-to
  opacity 0
