






























































































































@import '~variables'

.q-tabs, .q-tabs >>> .q-tabs-panes
  height 100%

.q-tab-pane
  padding 0
  height 100%

.oapps-tab
  overflow-y auto
