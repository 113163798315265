@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/Lato/Light/Lato-Light.ttf") format('truetype'), url("/fonts/Lato/Light/Lato-Light.woff2") format('woff2'), url("/fonts/Lato/Light/Lato-Light.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Lato/Regular/Lato-Regular.ttf") format('truetype'), url("/fonts/Lato/Regular/Lato-Regular.woff2") format('woff2'), url("/fonts/Lato/Regular/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Lato/Bold/Lato-Bold.ttf") format('truetype'), url("/fonts/Lato/Bold/Lato-Bold.woff2") format('woff2'), url("/fonts/Lato/Bold/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/Lato/Black/Lato-Black.ttf") format('truetype'), url("/fonts/Lato/Black/Lato-Black.woff2") format('woff2'), url("/fonts/Lato/Black/Lato-Black.woff") format('woff');
}
.sidebar-calc {
  overflow-y: auto;
  padding-bottom: 32px;
  height: 100%;
  background-color: #fff;
}
.sidebar-calc .sum-item {
  visibility: visible;
  max-height: 4000px;
  opacity: 1;
  transition: max-height 0.5s cubic-bezier(1, 0, 1, 0) 0s, visibility 0s linear 0s, opacity 0.2s linear 0.5s;
}
.sidebar-calc .sum-item-hidden {
  visibility: hidden;
  max-height: 0px;
  opacity: 0;
  transition: max-height 0.6s cubic-bezier(0, 1, 0, 1) 0s, visibility 0s linear 0.5s, opacity 0.2s linear 0s;
}
.sidebar-calc .add-button {
  margin: 20px;
  padding: 10px 15px;
  border-width: thin;
  border-style: solid;
  border-color: #000;
  border-radius: 20px;
  background-color: #fff;
  color: #464749;
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1.6rem;
  cursor: pointer;
  transition: opacity 1.5s ease-in;
}
.sidebar-calc .add-button--hidden {
  visibility: hidden;
  max-height: 0px;
  opacity: 0;
  transition: max-height 0.6s cubic-bezier(0, 1, 0, 1) 0s, visibility 0s linear 0.5s, opacity 0.2s linear 0s;
}
/*# sourceMappingURL=src/app/pages/data/components/sidebar-calc.css.map */