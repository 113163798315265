











































































































































































































































































































































@import '~variables'

.create-node-modal >>> .modal-content
  display flex
  flex-direction column
  overflow visible
  min-width 500px
  max-width @min-width
  .modal__header
    display flex
    align-items center
    padding $space-2 $space-3
    background-color black
    color white
    .modal-title
      margin 0
      line-height 1
    .close-button
      margin-left auto
      cursor pointer
  .modal__content
    flex 1
    overflow-y auto
    padding $space-3
    .text-field
      margin-bottom $space-1
    .label
      margin-bottom $space-1
      font-size $fs-h1
    .select-field
      margin-bottom $space-2
      font-size $fs-h2
    .create-button
      display block
      margin-top $space-2
      margin-left auto
    .node-list, .premise-type
      display flex
      justify-content center
      margin-bottom $space-1
      .o-button
        margin $space-1 $space-2
        background-color white
        text-transform uppercase
    .premise-type-container
      margin-bottom 'calc(%s + %s)' % ($space-1 23px)
      &--error
        margin-bottom $space-1 !important
        .premise-type-radio
          margin-bottom 'calc(%s + %s)' % ($space-2 $space-1)
    .premise-type
      flex-wrap wrap
      .premise-type-radio
        flex 1
        margin 0 $space-2
        font-size $fs-h2
    .premise-type__error-message
      color $negative
    .plug-type
      .plug-type__checkbox
        display block
        margin-bottom $space-2
        font-size $fs-h2
