


































































@import '~variables'

.country-input
  padding 0
  & >>> input
    padding $space-1
    height $space-3
    border 1px solid black
    border-radius $border-radius-smooth
    background-color white
    font-size $fs-h2
    &:focus
      box-shadow $shadow-elevation-2
