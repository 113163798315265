
























































































































@import '~variables'

.sidebar-out
  .add-button
    margin 20px
    padding 10px 15px
    border-width thin
    border-style solid
    border-color black
    border-radius 20px
    background-color white
    color $dark
    text-align center
    text-transform uppercase
    font-weight 700
    font-size $fs-h1
    cursor pointer
    transition opacity 1.5s ease-in
  .add-oapp-button
    margin $space-2 auto
    width fit-content
    background-color white
    color black
    text-transform uppercase
