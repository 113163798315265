.nxs {
  font-size: 12px !important;
}
.nsm {
  font-size: 20px !important;
}
.nmd {
  font-size: 24px !important;
}
.svg.nxs {
  min-width: 12px;
  min-height: 12px;
  width: 12px;
  height: 12px;
}
.svg.nsm {
  min-width: 20px;
  min-height: 20px;
  width: 20px;
  height: 20px;
}
.svg.nmd {
  min-width: 24px;
  min-height: 24px;
  width: 24px;
  height: 24px;
}
/*# sourceMappingURL=src/app/components/topology/icon-node-type.css.map */