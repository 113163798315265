























































































































































$xs_size ?= 12px
$sm_size ?= 20px
$md_size ?= 24px

.nxs
  font-size $xs_size !important

.nsm
  font-size $sm_size !important

.nmd
  font-size $md_size !important

.svg
  &.nxs
    min-width $xs_size
    min-height $xs_size
    width $xs_size
    height $xs_size
  &.nsm
    min-width $sm_size
    min-height $sm_size
    width $sm_size
    height $sm_size
  &.nmd
    min-width $md_size
    min-height $md_size
    width $md_size
    height $md_size
