@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/Lato/Light/Lato-Light.ttf") format('truetype'), url("/fonts/Lato/Light/Lato-Light.woff2") format('woff2'), url("/fonts/Lato/Light/Lato-Light.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Lato/Regular/Lato-Regular.ttf") format('truetype'), url("/fonts/Lato/Regular/Lato-Regular.woff2") format('woff2'), url("/fonts/Lato/Regular/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Lato/Bold/Lato-Bold.ttf") format('truetype'), url("/fonts/Lato/Bold/Lato-Bold.woff2") format('woff2'), url("/fonts/Lato/Bold/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/Lato/Black/Lato-Black.ttf") format('truetype'), url("/fonts/Lato/Black/Lato-Black.woff2") format('woff2'), url("/fonts/Lato/Black/Lato-Black.woff") format('woff');
}
.color-picker {
  padding: 8px;
}
.color-picker .title {
  margin: 0;
  margin-bottom: 8px;
  font-weight: 700;
  font-size: 1.1rem;
  line-height: 1;
}
.color-picker .color-list {
  display: flex;
  height: 70px;
  background-color: #00f;
}
.color-picker .color-list .color-item {
  flex: 1;
  height: 100%;
  cursor: pointer;
  transition: transform 0.2s ease;
}
.color-picker .color-list .color-item:hover {
  transform: scale(1.2);
}
/*# sourceMappingURL=src/app/components/inputs/color-picker.css.map */