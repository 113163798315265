






















































@import '~variables'

.tag-filter
  padding 16px
  .tag-filter__header
    margin-bottom 16px
    width 110px
    .header-title
      margin 0
      text-transform uppercase
      font-weight 700
      font-size $fs-body
      line-height 1
    .header-subtitle
      font-size $fs-body
  .tag-list
    display flex
    flex-direction column
    .tag-list__item-container
      margin-bottom 4px
      width 100%
      &:last-child
        margin-bottom 0
